import React, { useContext, useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import bedBuilderImage from "../images/bed-builder.png"

import twinSize from "../images/sizes/twin.png"
import fullSize from "../images/sizes/full.png"
import queenSize from "../images/sizes/queen.png"
import kingSize from "../images/sizes/king.png"
import StoreContext from "../context/StoreContext"

import Swatch from "./bed-builder-slider/Swatch"

const sizeConfig = {
  twin: { name: "twin", image: twinSize },
  full: { name: "full", image: fullSize },
  queen: { name: "queen", image: queenSize },
  king: { name: "king", image: kingSize },
}

const sizes = Object.keys(sizeConfig)

const comforterConfig = {
  cloud: {},
  breeze: {},
}
const comforters = Object.keys(comforterConfig)

const colorConfig = {
  darkGray: { css: "rgb(238, 235, 232)" },
  lightGray: { css: "rgb(238, 235, 232)" },
  blush: { css: "rgb(255, 232, 221)" },
  white: { css: "rgb(255, 255, 255)" },
  lightBlue: { css: "rgb(202, 211, 224)" },
  darkBlue: { css: "rgb(192, 205, 226)" },
}

const isEligible = title =>
  !["dev", "bundle", "test", "update"].find(term =>
    title.toLowerCase().includes(term)
  )

const categories = ["comforter", "duvet", "sheets", "pillow"]

const products = data => data.allShopifyProduct.edges.map(({ node }) => node)

const eligibleProducts = data =>
  products(data).filter(product => isEligible(product.title))

const productsForCategory = (category, products) =>
  products.filter(({ title }) => title.toLowerCase().includes(category))

const categoriesWithProducts = data => {
  const products = eligibleProducts(data)
  return categories.map(category => ({
    category,
    products: productsForCategory(category, products),
  }))
}

const sizeForVariant = variant => {
  let size
  if (variant.selectedOptions) {
    size = variant.selectedOptions.find(({ name, value }) => name === "Size")
      .value
  }
  return size
}

const colorForVariant = variant => {
  let color
  if (variant.selectedOptions) {
    const option = variant.selectedOptions.find(
      ({ name, value }) => name === "Color"
    )
    if (option) {
      color = option.value
    }
  }
  return color
}

const categoriesWithVariants = data =>
  categoriesWithProducts(data).map(({ category, products }) => ({
    category,
    variants: products.flatMap(product =>
      product.variants.map(variant => ({
        category,
        product,
        ...variant,
        size: sizeForVariant(variant),
        color: colorForVariant(variant),
      }))
    ),
  }))

const pillowSizeForComforterSize = comforterSize =>
  ["twin", "full", "queen"].includes(comforterSize) ? "standard" : "king"

const removeSpaces = str => str.split(" ").join("")

const filterVariants = (
  category,
  variants,
  targetSize,
  targetComforter,
  targetColor
) => {
  // console.log("filter variants for category", category)
  let matches = []
  // console.log(variants)
  switch (category) {
    case "comforter":
      matches = variants
        .filter(({ size }) => size && size.toLowerCase().includes(targetSize))
        .filter(
          ({ product }) =>
            product &&
            product.title &&
            product.title.toLowerCase().includes(targetComforter)
        )
      break
    case "duvet":
    case "sheets":
      matches = variants
        .filter(({ size }) => size && size.toLowerCase().includes(targetSize))
        .filter(
          ({ color }) =>
            color &&
            removeSpaces(color.toLowerCase()) === targetColor.toLowerCase()
        )

      break
    case "pillow":
      matches = variants
        .filter(
          ({ size }) =>
            size &&
            size.toLowerCase().includes(pillowSizeForComforterSize(targetSize))
        )
        .filter(({ title }) => title.includes("2"))
        .filter(({ title }) => title.includes("Medium"))
      break
    default:
      console.log("no bueno")
  }
  return matches
}

const categoriesWithVariantsForSizeComforterAndColor = (
  data,
  targetSize,
  targetComforter,
  targetColor
) =>
  categoriesWithVariants(data).map(({ category, variants }) => {
    return {
      category,
      variants: filterVariants(
        category,
        variants,
        targetSize,
        targetComforter,
        targetColor
      ),
    }
  })

// const showFooter = bed => {
//   const s = !!Object.values(bed).find(variant => !!variant)
//   console.log("show Footer?", s)
//   console.log(Object.values(bed).find(variant => !!variant))
// return s
// }

const Bedbuilder = ({ data }) => {
  const debug = false
  const [size, setSize] = useState()
  const [comforter, setComforter] = useState(null)
  const [color, setColor] = useState()
  // const [bed, setBed] = useState(emptyBed())
  const [selectedVariantIds, setSelectedVariantIds] = useState([])
  const { addVariantToCart } = useContext(StoreContext)

  // useEffect(() => {
  //   console.log("render bed")
  // }, [bed])

  useEffect(() => {
    // console.log("size, color or comforter changed")
    if (size && color && comforter) {
      const variants = categoriesWithVariantsForSizeComforterAndColor(
        data,
        size,
        comforter,
        color
      )
      // console.log("variants length", variants)
      setSelectedVariantIds(
        variants.map(({ variants }) => variants[0] && variants[0].shopifyId)
      )
    }
  }, [size, color, comforter, data])

  // const updateBed = (category, variant) => {
  //   console.log("update bed", category, variant.title)
  //   setIsMakingBed(true)
  //   setBed(prevBed => {
  //     const newBed = Object.assign(prevBed)
  //     newBed[category] = `${variant.product.title} ${variant.title}`
  //     newBed[category] = variant
  //     console.log("new bed", newBed)
  //     return newBed
  //   })
  //   setTimeout(() => {
  //     setIsMakingBed(false)
  //   }, 1)
  // }

  const addAllToCart = variantIds => {
    // console.log("variant ids", variantIds)
    addVariantToCart(variantIds, 1)
  }

  const reset = () => {
    setComforter(null)
    setSize(null)
    setColor(null)
  }

  return (
    <div style={{ maxWidth: "320px", margin: "auto", marginTop: "75px" }}>
      <h1 style={{ fontSize: "xx-large", textAlign: "center" }}>
        bed builder
        <img
          src={bedBuilderImage}
          alt="Bed Builder logo"
          style={{ width: "200px", margin: "auto" }}
        />
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        {sizes.map(s => (
          <div
            role="button"
            key={s}
            onClick={() => setSize(s)}
            onKeyDown={() => {}}
            tabIndex={0}
            style={{ outline: "none" }}
          >
            <b
              style={{
                fontSize: "x-large",
                textDecoration: s === size ? "underline" : "",
              }}
            >
              {s}
            </b>{" "}
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        {comforters.map(c => (
          <div
            key={c}
            onClick={() => {
              setComforter(c)
            }}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            style={{ outline: "none" }}
          >
            <b
              style={{
                fontSize: "3em",
                textDecoration: c === comforter ? "underline" : "",
              }}
            >
              {c}
            </b>{" "}
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          margin: "7px",
        }}
      >
        {Object.entries(colorConfig).map(([name, colorDetails]) => (
          <div
            key={name}
            onClick={() => setColor(name)}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            style={{ outline: "none" }}
          >
            <Swatch color={colorDetails.css} highlighted={color === name} />
          </div>
        ))}
      </div>

      {size && comforter && color ? (
        <>
          <div style={{ display: "flex", flexWrap: "wrap", width: "324px" }}>
            {categoriesWithVariantsForSizeComforterAndColor(
              data,
              size,
              comforter,
              color
            ).map(({ variants }) => {
              const variant = variants[0]
              return (
                <div
                  key={variant.id}
                  style={{
                    border: "1px solid black",
                    display: "inline-block",
                    width: "160px",
                  }}
                >
                  <img
                    src={variant.image.originalSrc}
                    alt="product detail"
                    style={{ width: "160px" }}
                  />
                  <div style={{ padding: "7px", textAlign: "center" }}>
                    <b>{variant.product.title}</b>
                    <br /> {variant.title}
                  </div>
                </div>
              )
            })}
          </div>
          <div
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "purple",
              fontWeight: "bold",
              fontSize: "3em",
              outline: "none",
            }}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            onClick={() => addAllToCart(selectedVariantIds)}
          >
            add to cart
          </div>
          <div
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "grey",
              outline: "none",
            }}
            onClick={() => reset()}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            reset
          </div>
        </>
      ) : (
        <div
          style={{
            fontSize: "1.5em",
            textAlign: "left",
            margin: "50px 0 0 50px",
            color: "grey",
          }}
        >
          Please choose a{" "}
          <ul>
            {!size && <li style={{ fontSize: "3em", color: "grey" }}>size</li>}
            {!comforter && (
              <li style={{ fontSize: "3em", color: "grey" }}>comforter</li>
            )}
            {!color && (
              <li style={{ fontSize: "3em", color: "grey" }}>color</li>
            )}
          </ul>
        </div>
      )}
      <div style={{ height: "1000px" }} />
      <Link to="/bed-builder-slider">BBS</Link>
      {debug && (
        <>
          <h1 style={{ marginTop: "100px" }}>DEBUG</h1>
          {categoriesWithVariants(data).map(({ category, variants }) => {
            const variant = variants[0]
            return (
              <>
                <h1>{category}</h1>
                <ul>
                  <li>
                    <h2>{variant.product.title}</h2>
                    <h3>{variant.title}</h3>
                  </li>
                </ul>
              </>
            )
          })}
        </>
      )}
    </div>
  )
}

export default Bedbuilder
export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          title
          shopifyId
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          id
          variants {
            id
            title
            shopifyId
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`
